import Contacts from '@/components/Contacts';
import styles from '@/components/Contacts/contacts.module.scss';
import Layout from '@/components/Layout';
import API from '@/api/index';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// Заглушка для переводов
const translate = s => s;

const ContactsPage = ({ data = {} }) => {
  return <Contacts data={data} />;
};

const breadcrumbs = [
  { title: translate('Главная'), route: '/' },
  { title: translate('Контакты'), route: '/contacts' },
];

ContactsPage.getLayout = (page, t) => (
  <Layout
    page={page.props}
    customPaddings={styles.paddings}
    paddings={false}
    breadcrumbs={breadcrumbs}
    title={t('Контакты — UNIFROST')}
  >
    {page}
  </Layout>
);

export async function getServerSideProps(context) {
  const props = { data: { activeOfficeSlug: context.params.slug } };

  try {
    const res = await API.get(`/v1/api/pages/contacts/`, {
      headers: { 'Accept-Language': context.locale },
    });

    if (res.status === 200) {
      if (res.data) props.data = res.data;
    }
  } catch (error) {}

  try {
    const res = await API.get(`/v1/api/contacts/offices/`, {
      headers: { 'Accept-Language': context.locale },
    });

    if (res.status === 200) {
      if (res.data) props.data.offices = res.data;
    }
  } catch (error) {}

  props.data.activeOfficeSlug = context.params.slug;

  return {
    props: {
      ...props,
      ...(await serverSideTranslations(context.locale, ['common', 'menu'])),
    },
    // revalidate: 60,
  };
}

export const getServerSidePaths = async () => {
  const response = await API.get(`/v1/api/contacts/offices/`);
  const paths = response.data.map(({ slug }) => ({ params: { slug } }));
  return {
    paths,
    fallback: 'blocking',
  };
};

export default ContactsPage;
