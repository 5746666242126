import dynamic from 'next/dynamic';
import Layout from '../components/Layout';
import API from '../api';
import style from '@/components/Main/Desktop/desktop.module.scss';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {getServerSidePaths} from "@/pages/contacts/[slug]";

const Desktop = dynamic(() => import('@/components/Main/Desktop'));
const Mobile = dynamic(() => import('@/components/Main/Mobile'));
const Device = dynamic(() => import('@/components/Main/Device'));

const Index = ({ data = { map: [] } }) => {
  return (
    <Device>
      {({ isMobile, isTablet }) => {
        return !isMobile ? (
          <Desktop touch={isTablet || isMobile} pageData={data} />
        ) : (
          <Mobile pageData={data} />
        );
      }}
    </Device>
  );
};

Index.getLayout = (page, t) => {
  return (
    <Layout
      page={page.props}
      headerLogoLinked={false}
      customPaddings={style.paddings}
      title={t('Создано морем, доставлено ЮНИФРОСТ — UNIFROST')}
      footer={false}
    >
      {page}
    </Layout>
  );
};

export async function getServerSideProps(context) {
  const props = { data: { map: [], partners: [] } };

  try {
    const res = await API.get(`/v1/api/pages/home/`, {
      headers: { 'Accept-Language': context.locale },
    });

    if (res.status === 200) {
      if (res.data) props.data = res.data;
    }
  } catch (error) {}

  try {
    const resMap = await API.get(`/v1/api/map/`, {
      headers: { 'Accept-Language': context.locale },
    });

    if (resMap.status === 200) {
      if (resMap.data) props.data.map = resMap.data;
    }
  } catch (error) {}

  try {
    const resPartners = await API.get(`/v1/api/partners/`, {
      headers: { 'Accept-Language': context.locale },
    });

    if (resPartners.status === 200) {
      if (resPartners.data) props.data.partners = resPartners.data;
    }
  } catch (error) {}

  return {
    props: {
      ...props,
      ...(await serverSideTranslations(context.locale, ['common', 'menu'])),
    },
    // revalidate: 60,
  };
}

export default Index;
